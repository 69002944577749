import Layout from '../Side-Nav/SideNav';

const MainNavigation = () => {
  return (
    <>
      <Layout></Layout>
    </>
  );
}

export default MainNavigation;