import * as React from 'react';
import * as mui from '../../../common/mui';

const HeaderMenus = () => {
  return (
    <React.Fragment>
      <mui.Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <mui.IconButton>
          <mui.AddAlertOutlinedIcon
            sx={{ color: 'white', width: 32, height: 32 }}
          ></mui.AddAlertOutlinedIcon>
        </mui.IconButton>

        <mui.Tooltip title="User Name">
          <mui.IconButton
            size="small"
            sx={{ ml: 2 }}
          >
            <mui.Avatar sx={{ width: 32, height: 32 }}></mui.Avatar>
          </mui.IconButton>
        </mui.Tooltip>
      </mui.Box>
    </React.Fragment>
  );
}
export default HeaderMenus;