import * as React from "react";
import styles from './PageContent.module.scss';
export interface LayoutProps {
  title: string;
  children: React.ReactNode
}

const PageContent = ({ title, children }: LayoutProps) => {
  return (
    <div className={styles.content}>
      <div className={styles.section__content}>
        <h1>{title}</h1>
        {children}
      </div>
    </div>
  );
}

export default PageContent;