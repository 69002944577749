import RootPage from './pages/Root-Page/RootPage';
import ErrorPage from './pages/Error-Page/ErrorPage';

const AppRoutes = [
  {
    path: '/',
    element: <RootPage />,
    errorElement: <ErrorPage />,
  },
];

export default AppRoutes;