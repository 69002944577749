import MainNavigation from '../../components/Main-Navigation/MainNavigation';

const RootPage = () => {
  return (
    <>
      <MainNavigation />
      <main>
      </main>
    </>
  );
}

export default RootPage;
