import React from 'react';
import PropTypes from 'prop-types';
import * as mui from '../../common/mui';
import { SvgIconComponent } from "@mui/icons-material";

export const BackButton = mui.styled(mui.IconButton)({
  border: '1px solid #a49595',
  height: '30px',
  width: '30px'
})
interface MenuItemProps {
  dynamicComponent: SvgIconComponent;
  size: number;
  className?: string;
}

export const MenuItem: React.FC<MenuItemProps> = ({
  dynamicComponent,
  size,
  className,
}) => {
  const IconComponent = dynamicComponent;
  const StyledList = mui.styled(IconComponent)({
    color: 'white',
    width: '40px',
    height: '40px'
  });
  return <StyledList className={className} />;
};
MenuItem.propTypes = {
  size: PropTypes.number.isRequired,
};