// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SideNav_sideNav__mkve7 {
  display: flex;
}
.SideNav_sideNav__navList__0jCmv {
  padding-left: 1px;
  color: #ffffff;
  text-decoration: none;
  font-size: 16px;
}
.SideNav_sideNav__mkve7 .SideNav_appBar__9JWm5 {
  background-color: #111111;
  color: #ffffff;
}
.SideNav_sideNav__icon__Vm32O {
  color: #ffffff;
  fill: #ffffff;
  margin-right: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/Side-Nav/SideNav.module.scss","webpack://./src/colors.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;AADF;AAGE;EACE,iBAAA;EACA,cCPI;EDQJ,qBAAA;EACA,eAAA;AADJ;AAIE;EACE,yBCZI;EDaJ,cCdI;ADYR;AAKE;EACE,cClBI;EDmBJ,aCnBI;EDoBJ,iBAAA;AAHJ","sourcesContent":["@import '../../colors.scss';\r\n\r\n.sideNav {\r\n  display: flex;\r\n\r\n  &__navList {\r\n    padding-left: 1px;\r\n    color: $White;\r\n    text-decoration: none;\r\n    font-size: 16px;\r\n  }\r\n\r\n  .appBar {\r\n    background-color: $Black;\r\n    color: $White;\r\n  }\r\n\r\n  &__icon {\r\n    color: $White;\r\n    fill: $White;\r\n    margin-right: 5px;\r\n  }\r\n}","$White: #ffffff;\r\n$Black: #111111;\r\n\r\n$Green: #007479;\r\n$LightGreen: #007c8214;\r\n$Orange: #ff673f;\r\n$Red: #e06363;\r\n$Primary: #6480c3;\r\n\r\n$Grey: #e9e9e9;\r\n$LightGrey: #f3f3f3;\r\n$BorderGrey: #dbdbdb;\r\n$DarkGrey: #717171;\r\n$BlueishGrey: #d2d2dd;\r\n\r\n$BlueishBlack: #70799a;\r\n$LighterBlack: #50494a;\r\n$LightBlack: #212b32;\r\n\r\n$Blue: #005ab8;\r\n$LightBlue: #e7eff5;\r\n$BrightBlue: #84c6fc;\r\n$DarkBlue: #06113d;\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sideNav": `SideNav_sideNav__mkve7`,
	"sideNav__navList": `SideNav_sideNav__navList__0jCmv`,
	"appBar": `SideNav_appBar__9JWm5`,
	"sideNav__icon": `SideNav_sideNav__icon__Vm32O`
};
export default ___CSS_LOADER_EXPORT___;
