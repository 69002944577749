import * as mui from '../common/mui';
import { Menu } from '../interfaces/menu';
const labels =  require('../assets/labels/en.json');


export const MENU_TYPES: Menu[] = [
    {
        text: labels.Dashboard,
        link: '/Dashboard',
        icon: mui.AssessmentIcon,
    },
    {
        text: labels.Models,
        link: 'model',
        icon: mui.AccountTreeIcon,
    },
    {
        text: labels.Datasets,
        link: '',
        icon: mui.AssessmentIcon,
    },
    {
        text: labels.Contexts,
        link: '',
        icon: mui.AddToDriveIcon
    },
    {
        text: labels.PromptPlayground,
        link: '',
        icon: mui.ApartmentIcon
    },
    {
        text: labels.Issues,
        link: '',
        icon: mui.AddToDriveIcon
    },
  ];
  