import { useRouteError } from 'react-router-dom';
import MainNavigation from '../../components/Main-Navigation/MainNavigation';
import PageContent from '../../components/Page-Content/PageContent';
const labels = require('../../assets/labels/en.json');
interface RouteError {
  data: string;
  error: {
    message: string;
  };
  internal: boolean;
  status: number;
  statusText: string;
}

const ErrorPage = () => {
  const error = useRouteError() as RouteError;

  let title = labels.PageNotFound;
  return (
    <>
      <MainNavigation />
      <div >
        <PageContent title={title}>
          <p>{error.error.message}</p>
        </PageContent>
      </div>
    </>
  );
}

export default ErrorPage;