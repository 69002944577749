// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PageContent_content__kXchK {
  text-align: center;
  font-size: 24px;
}
.PageContent_content__section__Hz7Kk {
  font-size: 16rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Page-Content/PageContent.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,eAAA;AACJ;AACI;EACE,gBAAA;AACN","sourcesContent":[".content {\r\n    text-align: center;\r\n    font-size: 24px;\r\n   \r\n    &__section {\r\n      font-size: 16rem;\r\n    }\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `PageContent_content__kXchK`,
	"content__section": `PageContent_content__section__Hz7Kk`
};
export default ___CSS_LOADER_EXPORT___;
