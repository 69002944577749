import * as React from 'react';
import * as mui from '../../common/mui';
import { Outlet } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { Theme, CSSObject } from '@mui/material/styles';
import { Menu } from '../../interfaces/menu';
import { MENU_TYPES } from '../../config/NavMenu.json';
import { BackButton, MenuItem } from '../Shared/Icons';
import HeaderMenus from '../Header/Header-Menu/HeaderMenu';
import { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import styles from './SideNav.module.scss';
export const MenuList: Menu[] = MENU_TYPES;

const drawerWidth = 300;
const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  color:'white',
  backgroundColor:'#111111',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  backgroundColor:'#111111',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

export const DrawerHeader = mui.styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const StyledList = mui.styled(mui.List)({
  '&& .Mui-selected, && .Mui-selected:hover': {
    backgroundColor: '#474545',
    borderLeft : '5px solid #e06363',
  },
  '& .MuiListItemButton-root:hover': {
    backgroundColor: 'grey',
    '&, & .MuiListItemIcon-root': {
      color: 'white',
    },
  },
});
interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

export const AppBar = mui.styled(mui.MuiAppBar, {
  shouldForwardProp: (prop:string) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const Drawer = mui.styled(mui.MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export default function MiniDrawer() {
  const theme = mui.useTheme();
  const [open, setOpen] = React.useState(true);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const handleListItemClick = (selectedIndex :number) => {
    setSelectedIndex(selectedIndex );
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <mui.Box className={styles.sideNav}>
      <mui.CssBaseline />
      <AppBar position="fixed" open={open} className={styles.appBar} >
        <mui.Toolbar>
          <mui.IconButton
            className={styles.sideNav__icon}
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              ...(open && { display: 'none' }),
            }} >
            <mui.MenuIcon />
          </mui.IconButton>
          <mui.Typography variant="h6" component="div"> BRAVE AI </mui.Typography>
          <div style={{ marginLeft: 'auto' }}>
            <HeaderMenus></HeaderMenus>   
          </div>       
        </mui.Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          {theme.direction !== 'rtl' && (
            <div className={styles.DrawerHeader}>
              <mui.Typography variant="h6"
                component="div" sx={{ marginRight:'20px' }}>
                CasperLabs
              </mui.Typography>
            </div>
          )}
          <BackButton color="inherit" onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? (
              <mui.ChevronRightIcon />
            ) : (
              <mui.ChevronLeftIcon />
            )}
          </BackButton>
        </DrawerHeader>
        <mui.Divider />
        <StyledList>
          {MenuList.map(({ text, link, icon }, index) => (
            <NavLink 
              key={text} to={link} 
              className={styles.sideNav__navList} >
              <mui.Tooltip title={text}>
              <mui.ListItem key={text} disablePadding>
                <mui.ListItemButton
                selected={selectedIndex === index}
                onClick={() => handleListItemClick(index)}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                  }}>
                  <mui.ListItemIcon
                    sx={{
                      minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center',
                    }}>
                  <MenuItem dynamicComponent={icon} size={30} />
                  </mui.ListItemIcon>
                  <mui.ListItemText
                    primary={text}
                    sx={{ opacity: open ? 1 : 0 ,fontSize:'20px'}} />
                </mui.ListItemButton>
              </mui.ListItem>
              </mui.Tooltip>
            </NavLink>
          ))}
        </StyledList>
      </Drawer>
      <mui.Box component="main" sx={{ flexGrow: 1, p: 5 }}>
        <DrawerHeader />
          <main>
            <Outlet />
          </main>
      </mui.Box>
    </mui.Box>
  );
}